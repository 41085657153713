import model from '~/assets/js/model';

export default model({
  entity: '',
  type: null,
  tag: '',
  owner: '',
  from: '',
  to: '',
  risk: {
    label: 'All',
    value: "all"
  },
  addresses: {
    more: {
      label: 'Any',
      value: "any"
    },
    size: ''
  }
});
