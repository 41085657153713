<template>
  <div data-cy="addressStatsData">
    <div class="table-wrap table-wrap__unoverflow table-wrap-stat">
      <o-table
        backend-sorting
        class="statistic-table stats-custom-table table__overflow-auto"
        :class="{ 'o-table-has-pagination': stats.length && totalPages > 1 }"
        :data="stats"
        :default-sort="[sortField, sortOrder]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :loading="loading"
        :mobile-cards="false"
        sort-icon="chevron-up"
        @sort="onSort"
      >
        <template v-for="column in columns">
          <o-table-column
            :key="column.id"
            v-bind="column"
            :visible="column.visible"
          >
            <template
              v-if="column.searchable"
              slot="searchable"
              slot-scope="props"
            >
              <gl-input
                v-if="column.field === 'address'"
                v-model.trim="formData.address"
                clearable
                :height="40"
                placeholder="Search by Address"
                @clear="clearField('address')"
                @enter="applyFilters"
              />
              <gl-input
                v-if="column.field === 'owner'"
                v-model="formData.owner"
                clearable
                :height="40"
                placeholder="Search by Owner"
                @clear="clearField('owner')"
                @enter="applyFilters"
              />
              <vSelect
                v-if="column.field === 'type'"
                v-model="formData.type"
                class="stat-select stat-select__address mr-1"
                :filterable="false"
                label="name"
                :options="typesOptions"
                placeholder="All types"
                @clear="clearType"
                @search="searchType"
              />
              <div
                v-if="column.field === 'type.score'"
                class="flex align-center"
              >
                <gl-input
                  v-model="formData.from"
                  v-mask="'###'"
                  class="mr-1"
                  :height="40"
                  min="0"
                  name="score"
                  placeholder="From"
                  style="max-width: 80px"
                  type="number"
                  @enter="applyFilters"
                  @input="handleScoring(formData.from, 'from')"
                />
                -
                <gl-input
                  v-model="formData.to"
                  v-mask="'###'"
                  class="ml-1"
                  :height="40"
                  min="0"
                  name="score"
                  placeholder="To"
                  style="max-width: 80px"
                  type="number"
                  @enter="applyFilters"
                  @input="handleScoring(formData.to, 'to')"
                />
              </div>
              <gl-input
                v-if="column.field === 'tags'"
                v-model="formData.tag"
                clearable
                :height="40"
                placeholder="Search by tags"
                @clear="clearField('tag')"
                @enter="applyFilters"
              />
              <div
                v-if="column.field === 'actions'"
                class="flex relative"
              >
                <div class="actions flex align-center">
                  <gl-menu-item
                    v-tooltip.top="'Clear all filters'"
                    class="mr-3"
                    :disabled="loading"
                    fullwidth
                    icon="reset-action"
                    :icon-height="24"
                    :icon-width="24"
                    @click="resetFilters"
                  />
                  <button
                    v-tooltip.top="'Apply filters'"
                    class="gl-button gl-button--dark gl-button--padder"
                    :disabled="loading"
                    @click="applyFilters"
                  >
                    go
                  </button>
                </div>
              </div>
            </template>
            <template v-slot:header>
              <div class="flex align-center">
                <div>
                  {{ column.label }}
                </div>
                <gl-icon
                  v-if="column.sortable && !checkSortAbility"
                  class="ml-1"
                  :disabled="checkSortAbility"
                  :height="24"
                  name="add-sorting"
                  :width="24"
                />
              </div>
            </template>
            <template v-slot="props">
              <div v-if="column.field === 'risk'">
                <span
                  class="capitalize"
                  :class="{ 'warning-text': props.row[column.field]}"
                >
                  {{ props.row[column.field] ? 'identified' : 'unidentified' }}
                </span>
              </div>
              <div v-else-if="column.field === 'address'">
                <span
                  v-tooltip.top="'Сlick to copy'"
                  class="pointer"
                  @click="copy(props.row.address)"
                >
                  {{ props.row.address ? trancateString(props.row.address, 8) : '-' }}
                </span>
              </div>
              <div v-else-if="column.field === 'type.score'">
                <div v-if="props.row.type.length">
                  <span
                    v-for="(type, index) in props.row.type"
                    :key="index"
                  >
                    {{ type.score }}
                  </span>
                </div>
                <div v-else-if="props.row.type">
                  {{ props.row.type.score || '-' }}
                </div>
                <div v-else>
                  -
                </div>
              </div>
              <div v-else-if="column.field === 'actions'" />
              <div
                v-else-if="column.field === 'type'"
                class="min-w-200"
              >
                <div v-if="props.row.type.length">
                  <GlTag
                    v-for="(type, index) in props.row.type"
                    :key="index"
                    class="mr-1 mb-1"
                    :score="type.score"
                    :tag="type.name"
                    :value="type._id"
                    @remove="removeTag(type, props.row.address)"
                  />
                </div>
                <div v-else-if="props.row.type">
                  <GlTag
                    class="mr-1 mb-1"
                    :score="props.row.type.score"
                    :tag="props.row.type.name"
                    :value="props.row.type._id"
                  />
                </div>
                <div v-else>
                  -
                </div>
              </div>
              <div
                v-else-if="column.field === 'tags'"
                class="min-w-200"
              >
                <GlTag
                  v-for="(tag, index) in props.row.tags"
                  :key="index"
                  class="mr-1 mb-1"
                  :score="tag.score"
                  :tag="tag.name"
                  :value="tag._id"
                  @remove="removeTag(tag, props.row.address)"
                />
              </div>
              <div v-else>
                {{ props.row[column.field] ? props.row[column.field] : '-' }}
              </div>
            </template>
          </o-table-column>
        </template>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-2">
        <div class="flex align-center pa-3 m-column m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 bold m-fs-12 m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
        <div class="flex align-center">
          <div style="width: 200px">
            <VueSkeletonLoader
              v-if="loadingTotal"
              animation="wave"
              :color="'#bec3d8'"
              :height="20"
              :radius="'2'"
              type="rect"
            />
          </div>
          <o-pagination
            v-if="stats.length && totalPages > 1 && !loadingTotal"
            class="stat-pagination"
            :current.sync="currentPage"
            order="right"
            :per-page="perPage"
            simple
            :total="total"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled || loadingTotal"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled || loadingTotal"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
    </div>
    <DeleteTagModal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      :tag="removedTag"
      @close="showDeleteModal = false"
      @submit="deleteTag"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapState } from 'vuex';
// Components
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
import GlInput from '@/components/gl-input'
import GlTag from '@/components/gl-tag'
import GlLoader from '@/components/gl-loader'
import DeleteTagModal from '../../tagging/modals/DeleteTagModal'
import vSelect from 'vue-select'
import VueSkeletonLoader from 'skeleton-loader-vue';
// Models
import filtersModel from '../models/addressFiltersModel'
// Libs
import _ from 'lodash'
//Static
import { riskOptions, pagesOptions } from '../static/filtersLabelsData'
// Utils
import { trancateString } from "@/utils/text-formatter";

const ETHEREUM_NETWORK = 'eth'

export default {
  components: {
    VueSkeletonLoader,
    GlMenuItem,
    GlIcon,
    GlLoader,
    GlTag,
    DeleteTagModal,
    vSelect,
    GlInput,
  },
  props: {
    activeTab: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      pagesOptions: pagesOptions,
      formData: filtersModel(),
      oldData: null,
      showDeleteModal: false,
      columns: [
        {
          field: 'address',
          label: 'address',
          sortable: true,
          searchable: true,
          visible: true
        },
        {
          field: 'owner',
          label: 'Address owner',
          searchable: true,
          sortable: true,
          visible: true
        },
        {
          field: 'type',
          label: 'Type',
          searchable: true,
          sortable: false,
          visible: true,
          width: '300px'
        },
        {
          field: 'type.score',
          label: 'Score of type',
          sortable: false,
          searchable: true,
          visible: true
        },
        {
          field: 'tags',
          label: 'Tags',
          searchable: true,
          visible: true
        },
        {
          field: 'cluster',
          label: 'Cluster ID',
          width: '140px',
          sortable: true,
          visible: true
        },
        {
          field: 'actions',
          label: '',
          searchable: true,
          visible: true
        },
      ],
      riskOptions: riskOptions,
      loading: false,
      loadingTotal: true,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      defaultSortOrder: 'asc',
      sortField: '',
      removedTag: '',
      sortOrder: 'asc',
      stats: [],
      typesOptions: [],
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData']),
    isDefaultFiltersState() {
      return _.isEqual(this.formData, this.oldData)
    },
    checkSortAbility() {
      return JSON.stringify(this.formData) === JSON.stringify(filtersModel())
    },
  },
  watch: {
    'formData.risk': 'handleRisk',
    coinData: {
      handler(coin) {
        this.changeCoin(coin)
      },
      deep: true
    },
    activeTab: {
      handler(tabName) {
        if (tabName === 'addresses') this.loadData()
      },
      immediate: true
    },
  },
  async created() {
    this.perPage = localStorage.getItem('addresses-stat-per-page') || this.perPage
    this.oldData = _.cloneDeep(this.formData)
    localStorage.setItem('addresses-stat-per-page', this.perPage)
    this.columns = this.columns.map(el => ({
      ...el,
      visible: !(el.field === 'cluster' && this.$can('use', 'eth'))
    }))
  },
  methods: {
    trancateString,
    changeCoin() {
      this.loadData(true)
      this.columns = this.columns.map(el => ({
        ...el,
        visible: !(el.field === 'cluster' && this.$can('use', 'eth'))
      }))
    },
    ...mapActions({
      getAddressStatisticsInfo: 'statistics/getAddressStatisticsInfo',
      getTotalAddresses: 'statistics/getTotalAddresses',
      deleteAddressTag: 'tagging/deleteAddressTag',
      getTypes: 'types/getTypes',
    }),
    handleScoring(score, field) {
      if (score && Number(score) > 100) {
        this.formData[field] = 100
      }
    },
    async copy(value) {
      await navigator.clipboard.writeText(value).then(() => {
        this.$toasted.global.success({ message: 'Copied!' })
      })
    },
    searchType(search) {
      if (!search) {this.typesOptions = [] } else {

        this.getTypes({ search }).then(({ data }) => {
          this.typesOptions = data.typeData
        })
      }
    },
    removeTag(tag, assignAddress) {
      this.removedTag = {...tag, assignAddress }
      this.showDeleteModal = true
    },
    deleteTag(tag) {
      if (tag !== undefined) {
        this.deleteAddressTag({ name: tag, address: this.removedTag.assignAddress }).then(() => {
          this.showDeleteModal = false
          this.applyFilters(false)
          this.removedTag = ''
        })
      }
    },
    resetFilters() {
      this.formData = _.cloneDeep(this.oldData)
      this.applyFilters(true)
    },
    applyFilters(needTotal = true) {
      this.loadData(needTotal)
    },
    countChange() {
      localStorage.setItem('addresses-stat-per-page', this.perPage)
      this.loadData(true)
    },
    handleRisk({ value }) {
      if (value && value === 'all') this.loadData()
    },
    async loadData(needTotal = true) {
      const sendModelData = this.formattedSendData(needTotal)

      if (needTotal) {
        this.loadingTotal = true
        this.getTotalAddresses(sendModelData).then(({ data: { totalItems } }) => {
          this.total = totalItems
          this.totalPages = Math.ceil(this.total / this.perPage)
          this.loadingTotal = false
        })
      }

      this.loading = true
      await this.getAddressStatisticsInfo(sendModelData).then(({ data: { result } } ) => {
        this.stats = result.map((item) => ({
          ...item,
          type: item.type || []
        }))
        this.loading = false
      }).catch(() => {
        this.stats = []
        this.loading = false
        this.loadingTotal = false
        this.$toasted.global.error({ message: 'Request error'})
      }).finally(() => {
        if (this.coinData.key === ETHEREUM_NETWORK) {
          this.columns.find(col => col.field === 'cluster').visible = false
        }
      })
    },
    onSort(field, order) {
      if (this.checkSortAbility) return
      this.sortOrder = order
      this.sortField = field
      this.loadData(false)
    },
    formattedSendData(needTotal = true) {
      this.perPage = localStorage.getItem('addresses-stat-per-page') || this.perPage

      if (needTotal) this.currentPage = 1

      return {
        address: this.formData.address || undefined,
        tag: this.formData.tag || undefined,
        description: this.formData.description || undefined,
        owner: this.formData.owner || undefined,
        cluster: this.formData.cluster || undefined,
        type: this.formData.type && this.formData.type.name
          ? this.formData.type.name
          : undefined,
        from: this.formData.from || undefined,
        to: this.formData.to || undefined,
        risk: this.formData.risk.value,
        sortField: this.sortField || undefined,
        count: this.perPage,
        skip: (this.currentPage - 1) * this.perPage,
        sortOrder: this.sortOrder || undefined
      }
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData(false)
    },
    clearType() {
      this.formData.type = ''
    },
    clearField(field) {
      this.formData[field] = ''
    },
  }
}
</script>

<style>
.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select__address {
  width: 100% !important;
  min-width: 150px;
}

.stat-select__address .vs__dropdown-toggle {
  height: 40px !important;
  /*background: #fff;*/
  background-color: var(--pale-grey) !important;
  border: none;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.stats-custom-table .o-table .o-table__th {
  padding: 10px !important;
}

.filter-item__label {
  position: absolute;
  top: -17px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-grey-6-e);
}

.table-wrap-stat {
  box-shadow: none;
  border-radius: unset;
  border-top: 1px solid #dbdbdb;
}
</style>
