<template>
  <GlPageWrap
    hide-title-on-mobile
    title="Statistics"
  >
    <template #actions>
      <GlCoinSelect
        class="m-fullwidth"
        @change="changeCoin"
      />
    </template>
    <div class="statistic flex m-column">
      <div class="statistic-card">
        <div class="statistic-card__item">
          <div class="statistic-card__title">
            Labeled Addresses
          </div>
          <div
            class="statistic-card__value"
            data-cy="taggedAddresses"
          >
            {{ taggedAddresses ? toComaSeparate(taggedAddresses) : '-' }}
          </div>
        </div>
        <div
          v-if="!$can('use', 'eth')"
          class="divider-full"
        />
        <div
          v-if="!$can('use', 'eth')"
          class="statistic-card__item"
        >
          <div class="statistic-card__title">
            Addresses in Labeled Clusters
          </div>
          <div
            class="statistic-card__value"
            data-cy="addressesInTaggedClusters"
          >
            {{ addressesInTaggedClusters ? toComaSeparate(addressesInTaggedClusters) : '-' }}
          </div>
        </div>
        <div
          v-if="!$can('use', 'eth')"
          class="divider-full"
        />
        <div
          v-if="!$can('use', 'eth')"
          class="statistic-card__item"
        >
          <div class="statistic-card__title">
            Labeled Clusters
          </div>
          <div
            class="statistic-card__value"
            data-cy="taggedClusters"
          >
            {{ taggedClusters ? toComaSeparate(taggedClusters) : '-' }}
          </div>
        </div>
      </div>
      <div class="statistic-calculated">
        <div class="flex space-between t-mb-3 m-column m-gap-3">
          <div class="statistic-calculated__item mr-2">
            <div class="statistic-calculated__item--title">
              Calculated at
            </div>
            <div
              class="statistic-calculated__item--value"
              data-cy="calculatedAt"
            >
              {{ timestamp ? formatDate(timestamp, 'dd.MM.yyyy, HH:mm OOOO') : '-' }}
            </div>
          </div>
          <div class="statistic-calculated__item">
            <div class="statistic-calculated__item--title">
              block
            </div>
            <div
              class="statistic-calculated__item--value"
              data-cy="lastBlock"
            >
              {{ lastBlock ? toComaSeparate(lastBlock) : '-' }}
            </div>
          </div>
        </div>
        <GlProgress
          v-if="progress < 100 && inProgress"
          class="mb-1 mt-1"
          :progress="progress"
        />
        <div>
          <button
            v-if="!inProgress"
            class="gl-button gl-button--full gl-button--dark gl-button--padder"
            :disabled="inProgress"
            @click="recalc"
          >
            recalculate
          </button>
          <div
            v-else
            class="fs-14 bold"
          >
            Recalculation is in progress…
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-end space-between tabs-wrap">
      <div class="flex m-fullwidth">
        <div
          v-if="!$can('use', 'eth')"
          class="tab m-flex-1 mr-4 m-mr-0"
          :class="{'tab-active': activeTab === 'clusters'}"
          @click="handleActiveTab('clusters')"
        >
          Clusters
        </div>
        <div
          class="tab"
          :class="{'tab-active': activeTab === 'addresses' && !$can('use', 'eth'),
                   'm-flex-1': !$can('use', 'eth'),
                   'main-text-important': $can('use', 'eth') }"
          @click="handleActiveTab('addresses')"
        >
          Addresses
        </div>
      </div>
    </div>
    <ClusterStat
      v-show="activeTab === 'clusters'"
      :active-tab="activeTab"
    />
    <AddressStat
      v-show="activeTab === 'addresses'"
      :active-tab="activeTab"
    />
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions } from 'vuex'
// Components
import ClusterStat from './components/ClusterStat'
import GlProgress from '@/components/gl-progress'
import AddressStat from './components/AddressStat'
import GlPageWrap from "@/components/layout/gl-page-wrap";
import GlCoinSelect from "@/components/gl-coin-select";
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";

export default {
  components: {
    GlPageWrap,
    GlProgress,
    ClusterStat,
    AddressStat,
    GlCoinSelect,
  },
  data() {
    return {
      activeTab: 'clusters',
      addressesInTaggedClusters: null,
      taggedAddresses: null,
      taggedClusters: null,
      timestamp: null,
      lastBlock: null,
      progress: 0,
      inProgress: false,
      statRecalcHandler: null,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.query.tab) this.activeTab = to.query.tab
      }
    }
  },
  created() {
    this.loadStats()
    this.statRecalcHandler = setInterval(() => {
      this.loadStats()
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.statRecalcHandler);
  },
  methods: {
    formatDate,
    toComaSeparate,
    ...mapActions({
      getTagStats: 'statistics/getTagStats',
      generateTagStats: 'statistics/generateTagStats'
    }),
    handleActiveTab(tab) {
      this.activeTab = tab
      this.$router.replace({ name: 'statistics', query: { tab: tab } })
    },
    changeCoin() {
      this.loadStats()
    },
    async loadStats() {
      if (this.$can('use', 'eth')) {
        await this.handleActiveTab('addresses')
      }
      this.getTagStats().then(({ data: {
        addressesInTaggedClusters,
        taggedAddresses,
        taggedClusters,
        timestamp,
        lastBlock,
        progress,
        inProgress
      } }) => {
        this.addressesInTaggedClusters = addressesInTaggedClusters
        this.taggedAddresses = taggedAddresses
        this.taggedClusters = taggedClusters
        this.timestamp = timestamp
        this.lastBlock = lastBlock
        this.inProgress = inProgress
        this.progress = Number(progress) * 100
      }).catch(() => {
        this.addressesInTaggedClusters = null
        this.taggedAddresses = null
        this.taggedClusters = null
        this.timestamp = null
        this.lastBlock = null
        this.inProgress = false
        this.progress = null
      })
    },
    recalc() {
      this.generateTagStats()
      this.loadStats()
    },
  }
}
</script>

<style>
.statistic {
  margin-bottom: 40px;
}

.statistic-card__item {
  margin: 4px;
}

.statistic-card, .statistic-calculated {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
}

.statistic-card {
  display: flex;
  flex: 3;
  justify-content: space-between;
  margin-right: 32px;
  padding: 28px 44px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  font-weight: 600;
  color: var(--space-cadet);
  letter-spacing: normal;
}

.statistic-calculated {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 24px 32px 16px 32px;
}

.statistic-calculated__item--title {
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-grey-6-e);
  margin-bottom: 8px;
  text-transform: uppercase;
}

.statistic-calculated__item--value {
  font-size: 16px;
  font-weight: 600;
  color: var(--space-cadet);
}

.statistic-card__title {
  font-size: 18px;
  margin-bottom: 24px;
}

.statistic-card__value {
  font-size: 40px;
}

.stat-select .vs__dropdown-toggle {
  height: 30px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.statistic-table .o-table thead {
  position: sticky;
  top: 0px;
  background: #fff;
}

.tabs-wrap {
  position: sticky;
  top: -30px;
  z-index: 100;
  padding: 14px;
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

@media (max-width: 1200px) {
  .statistic-card {
    flex-direction: column;
    flex: 2;
    margin-right: 16px;
    padding: 12px 20px;
  }
  .divider-full {
    width: 100%;
    height: 1px;
  }
  .statistic-card__item {
    margin-top: 16px;
  }
  .tabs-wrap {
    position: relative;
    top: 0px;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .statistic-calculated  {
    height: min-content;
  }
}

@media (max-width: 767px) {
  .statistic-card  {
    margin-right: 0;
    margin-bottom: 16px;
  }
  .statistic-calculated  {
    padding: 24px 16px;
  }
  .tabs-wrap {
    padding-bottom: 32px;
  }
}
</style>
